body {
  margin: 0;
  font-family: "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FAFBFD;
}

.code {
  font-family:Consolas,Monaco,Lucida Console,Liberation Mono,DejaVu Sans Mono,Bitstream Vera Sans Mono,Courier New, monospace;
}

.color-primary-0 { color: #008181 }	/* Main Primary color */
.color-primary-1 { color: #34A1A1 }
.color-primary-2 { color: #149797 }
.color-primary-3 { color: #006969 }
.color-primary-4 { color: #005050 }

.color-secondary-1-0 { color: #0F3690 }	/* Main Secondary color (1) */
.color-secondary-1-1 { color: #4566B1 }
.color-secondary-1-2 { color: #254DA8 }
.color-secondary-1-3 { color: #0A2B75 }
.color-secondary-1-4 { color: #061F59 }

.color-secondary-2-0 { color: #D78F00 }	/* Main Secondary color (2) */
.color-secondary-2-1 { color: #FFC552 }
.color-secondary-2-2 { color: #FBB321 }
.color-secondary-2-3 { color: #AF7500 }
.color-secondary-2-4 { color: #855900 }

.color-complement-0 { color: #D76100 }	/* Main Complement color */
.color-complement-1 { color: #FFA052 }
.color-complement-2 { color: #FB8421 }
.color-complement-3 { color: #AF4F00 }
.color-complement-4 { color: #853C00 }

.bg-color-primary-0 { background-color: #008181 }	/* Main Primary color */
.bg-color-primary-1 { color: #34A1A1 }
.bg-color-primary-2 { color: #149797 }
.bg-color-primary-3 { color: #006969 }
.bg-color-primary-4 { color: #005050 }

.bg-color-secondary-1-0 { color: #0F3690 }	/* Main Secondary color (1) */
.bg-color-secondary-1-1 { color: #4566B1 }
.bg-color-secondary-1-2 { color: #254DA8 }
.bg-color-secondary-1-3 { color: #0A2B75 }
.bg-color-secondary-1-4 { color: #061F59 }

.bg-color-secondary-2-0 { color: #D78F00 }	/* Main Secondary color (2) */
.bg-color-secondary-2-1 { color: #FFC552 }
.bg-color-secondary-2-2 { color: #FBB321 }
.bg-color-secondary-2-3 { color: #AF7500 }
.bg-color-secondary-2-4 { color: #855900 }

.bg-color-complement-0 { color: #D76100 }	/* Main Complement color */
.bg-color-complement-1 { color: #FFA052 }
.bg-color-complement-2 { color: #FB8421 }
.bg-color-complement-3 { color: #AF4F00 }
.bg-color-complement-4 { color: #853C00 }

a {
  text-decoration: none;
}
