.App-logo-anchor {
  position: absolute;
  height: 48px;
  display: flex;
  padding: 10px 0 10px 10px;
  z-index: 500;
}

@media only screen and (max-width: 900px) {
  .App-logo-anchor > img {
    height: 20px;
  }
}

.App-profile-container {
  position: absolute;
  right: 20px;
  display: "flex";
  flex-direction: "row";
  margin-top: 10px;
  z-index: 500;
  cursor: pointer;
}

.App-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.Page-content {
  max-width: 1300px;
}
.Tabs-content {

}
.App-section {
  margin: 30px;
  text-align: left;
  width: 1100px;
}

.App-section-loading {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#clip {
  width: 160px;
}
.MuiChip-label {
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block !important;
}
.underline {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px; /* Adjust the height as needed */
  background: white; /* Set the color of the underline */
}
.paper {
  margin-top: 20px;
  box-shadow: -10px 10px 20px 0 rgba(30, 30, 30, 0.05);
  background-color: #ffffff;
  padding: 20px;
  overflow-x: overlay;
}
.centered {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.paper-description {
  margin-bottom: 20px;
}
.pi-uploader {
  height: 260px;
  border-radius: 5px;
  background: linear-gradient(to right, #f0f1f1 50%, rgba(255, 255, 255, 0) 0%),
    linear-gradient(#f0f1f1 50%, rgba(255, 255, 255, 0) 0%),
    linear-gradient(to right, #f0f1f1 50%, rgba(255, 255, 255, 0) 0%),
    linear-gradient(#f0f1f1 50%, rgba(255, 255, 255, 0) 0%);
  background-position: top, right, bottom, left;
  background-repeat: repeat-x, repeat-y;
  background-size: 10px 1px, 1px 10px;

  display: flex;
  justify-content: center;
  align-items: center;
  color: #a0a0a0;
}
.pi-uploader:focus {
  outline: 0;
}
.pi-uploader-content {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.pi-uploader-content-text {
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.pi-uploader-content-success-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  z-index: 100;
}

.table-center {
  width: 100%;
  margin-top: 10px;
}

.table-center-text {
  vertical-align: middle;
  margin-bottom: 10px;
  display: inline-block;
}

@media only screen and (max-width: 1100px) {
  .App-section {
    width: 900px;
  }
}

@media print {
  .App-section {
    width: 100%;
  }

  .no-print {
    display: none;
  }
}

@media only screen and (max-width: 900px) {
  .App-section {
    width: 600px;
  }
  #login-title {
    display: none;
  }
  #fincv-tabs {
    margin-top: 30px;
  }
}
@media only screen and (max-width: 600px) {
  .App-section {
    width: 300px;
  }
  .pi-uploader {
    height: 350px;
  }
  .pi-uploader-content-success-link {
    flex-direction: column;
  }
  #pi-upload-arrow-icon {
    display: none;
  }
  .pi-uploader-content-success-link-text {
    margin-bottom: 20px;
  }
}
.tablePrimary {
  white-space: nowrap;
  margin: 0;
}
.tableSecondary {
  white-space: nowrap;
  margin: 0;
  color: darkgray;
}
.profil-wrapper {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}
.profil-container {
  max-width: 550px;
  width: 100%;
}
.profil-item {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 16px;
}
#bemaerkning {
  white-space: "pre-line";
  text-align: "left";
}


.MUIRichTextEditor-editorContainer-5{
  min-height: 0px;
}

.MuiCardContent-root{
  min-width: 300px;
}
